import React from 'react';
import { HeaderStyles } from '../../templates/shop-performance/ShopPerformanceStyles';
import Icon from '../../styles/atoms/icons';

const HeaderShopPerformance = ({ questionCount, step }) => {
  return (
    <HeaderStyles
      questionCount={questionCount}
      step={step}
    >
      <div className="container top-bar">
        <Icon id="sm-dark-logo" className="nav-logo" isImage />
      </div>
    </HeaderStyles>
  );
};
export default HeaderShopPerformance;
